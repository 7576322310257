// Set the delay between slides
var delay = 4000;

// Get an array of any elements with a class of 'fade'
var slides = Array.from(document.querySelectorAll('.container div'));

// Function to cycle through each slide, show it, then hide it after the specified delay
function cycleSlides() {
  // iterate through the elements in the slides array
  for (var i = 0; i < slides.length; i++) {
    // Show the slide
    showSlide(slides[i], delay * i);

    // Hide the slide after the specified delay
    hideSlide(slides[i], delay * i + delay);
  } // End of map iterator
}

// Function to fade in a single slide
function showSlide(slide, _delay) {
  //Add the '--in' class
  setTimeout(() => {
    slide.classList.add('--in');
  }, _delay);
}

// Function to fade out a single slide
function hideSlide(slide, _delay) {
  // Remove the '--in' class
  setTimeout(() => {
    slide.classList.remove('--in');
  }, _delay);
}

// Call our cycle function for the first time
cycleSlides();

// Restart our cycle function each time it finishes
setInterval(() => {
  cycleSlides();
}, delay * slides.length);
